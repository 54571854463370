import { isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { BlankBrandPage } from "../common/BlankBrandPage";

const AuthIsLoaded = ({ children }: { children: any }) => {
  // @ts-ignore
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) {
    return <BlankBrandPage />;
  }
  return children;
};

export default AuthIsLoaded;
