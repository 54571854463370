import { configureStore } from "@reduxjs/toolkit";
import {
  firebaseReducer,
  // getFirebase,
  // actionTypes as rrfActionTypes,
} from "react-redux-firebase";
// import { firestoreReducer, constants as rfConstants } from "redux-firestore";

// const extraArgument = {
//   getFirebase,
// };
// const middleware = (getDefaultMiddleware: any) => {
//   return [
//     ...getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [
//           // just ignore every redux-firebase and react-redux-firebase action type
//           ...Object.keys(rfConstants.actionTypes).map(
//             (type) => `${rfConstants.actionsPrefix}/${type}`
//           ),
//           ...Object.keys(rrfActionTypes).map(
//             (type) => `@@reactReduxFirebase/${type}`
//           ),
//         ],
//         ignoredPaths: ["firebase", "firestore"],
//       },
//       thunk: {
//         extraArgument,
//       },
//     }),
//   ];
// };

const rootReducer = configureStore({
  reducer: {
    firebase: firebaseReducer,
    // firestore: firestoreReducer,
  },
  // middleware,
});

export type RootState = {
  firebase: any;
  // firestore: any;
};

export default rootReducer;
