import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useHistory } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    login: {
      display: "flex",
      // flexDirection: "column",
      // justifyContent: "center",
      // alignItems: "center",
      height: "100vh",
      backgroundColor: "#20315a",
      // backgroundImage: "url('images/login-background.png')",
      backgroundSize: "cover",
    },
    infoArea: {
      position: "relative",
      height: "100%",
      width: "60%",
      // backgroundImage:
      //   Math.random() > 0.5
      //     ? "url('images/login-background.png')"
      //     : "url('images/login-background.png')",
      // backgroundSize: "cover",
    },
    // infoAreaOverlay: {
    //   position: "absolute",
    //   height: "100%",
    //   width: "100%",
    //   display: "flex",
    //   flexDirection: "column",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   backgroundColor: "rgba(0,0,0,0.7)",
    // },
    authArea: {
      position: "relative",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    firebaseAuthContainer: {
      position: "absolute",
      padding: "8px",
      backgroundColor: "white",
      borderRadius: "24px",
    },
    styledFirebaseAuth: {
      // background: "red",
    },
  })
);

export const Login = ({ admin }: { admin: boolean }) => {
  const classes = useStyles();
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const uiConfig = {
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    callbacks: {
      // @ts-ignore
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        setShowLoader(true);
        // @ts-ignore
        firebase.handleRedirectResult(authResult).then(async () => {
          // TODO: adding teamId should not be here, find a better way to do this.
          const userInfo = authResult.additionalUserInfo;
          try {
            if (
              !authResult.user.emailVerified &&
              userInfo.isNewUser &&
              userInfo.providerId === "password"
            ) {
              await authResult.user.sendEmailVerification();
            }

            history.push("/dashboard");
          } catch (e) {
            console.error(e);
          }
        });
        return false;
      },
    },
  };

  return (
    <div className={classes.login}>
      {/* <div className={classes.infoArea}> */}
      {/* <div className={classes.infoAreaOverlay}>
          <b style={{ color: "#ddd", fontSize: "100px" }}>KorrAI</b>
          <b style={{ color: "#aaa", fontSize: "64px" }}>
            INSIGHT FROM IMAGERY
          </b>
        </div> */}
      {/* </div> */}
      <div className={classes.authArea}>
        {showLoader ? (
          <img src="/images/earth_happy.gif" alt=""></img>
        ) : (
          <div
            className={classes.firebaseAuthContainer}
            // TODO: clean this logic now
            id={admin ? "firebaseAuthContainerAdmin" : "firebaseAuthContainer"}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "32px",
                border: "8px solid #20315a",
                padding: "40px",
                background: "white",
                borderRadius: "24px",
              }}
            >
              <img src="/images/KorrAI_brand_logo_colored.png" alt=""></img>
              <div
                style={{
                  height: "4px",
                  width: "80%",
                  backgroundColor: "#20315a",
                }}
              ></div>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
                className={classes.styledFirebaseAuth}
              />
            </div>
          </div>
        )}
        {/* <h2 style={{ color: "#ddd" }}>Unable to login?</h2>
        <b style={{ color: "#ddd" }}>
          Contact us @ <a href="https://www.korrai.com/contact">korrai.com</a>
        </b> */}
      </div>
    </div>
  );
};
