export const BlankBrandPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        // color: "#5046f2",
        // backgroundColor: "#7986cb",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // fontFamily: "monospace",
      }}
    >
      <img
        alt="KorrAI"
        src="/images/KorrAI_brand_logo_colored.png"
        // style={{ width: "50%" }}
      />
      {/* <b style={{ fontSize: "128px" }}>KorrAI</b> */}
      {/* <h2>LOADING...</h2> */}
    </div>
  );
};
