import firebase from "firebase";
import Button from "@mui/material/Button";

export const SignoutButton = () => {
  return (
    <Button
      aria-label="navigate"
      size="small"
      variant="outlined"
      color="success"
      style={{
        position: "fixed",
        top: 8,
        right: 32,
        zIndex: 2,
        color: "white",
        borderColor: "white",
      }}
      onClick={(_) => {
        firebase.auth().signOut();
        // @ts-ignore
        window.location.reload();
      }}
    >
      Sign Out
    </Button>
  );
};
