import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flexDirection: "column",
      fontSize: 14,
    },
    child: { padding: "10px 0px" },
  })
);

export function MobileFallback() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.child}>
        This application is not supported in mobile or tablet devices.
      </div>
      <div className={classes.child}>
        Please try to open in the laptop or desktop.
      </div>
    </div>
  );
}
