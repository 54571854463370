// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyA61kv_yOUTc6xRHHUsPE10aiwlkglpVZQ",
  authDomain: "korrai-dev.firebaseapp.com",
  projectId: "korrai-dev",
  storageBucket: "korrai-dev.appspot.com",
  messagingSenderId: "314175055218",
  appId: "1:314175055218:web:ede8b23fce9817072ee9eb",
  measurementId: "G-4TYPG5SZ3S",
};
