import { Switch, Route, Redirect } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { MobileFallback } from "./components/Fallback";
import { Login } from "./components/Auth/Login";
import { PrivateRoute } from "./components/Auth/PrivateRoute";
import { SignoutButton } from "./components/Auth/SignoutButton";
import "./App.css";

const App = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route path="/login">
        <Login admin={false} />
      </Route>
      <PrivateRoute path="/dashboard">
        {isDesktopOrLaptop ? <Main /> : <MobileFallback />}
      </PrivateRoute>
    </Switch>
  );
};

const Main = () => {
  return (
    <div>
      <SignoutButton />
      <iframe
        title="deformation app"
        style={{
          height: "100vh",
          width: "100vw",
          border: 0,
          backgroundImage: "url('/images/KorrAI_brand_logo_colored.png')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        src="https://korrai.shinyapps.io/KorrAI_Defromation_Visualizer/"
      ></iframe>
    </div>
  );
};

export default App;
