import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"; // <- needed if using firestore
// import { createFirestoreInstance } from "redux-firestore";
import { BrowserRouter } from "react-router-dom";
import AuthIsLoaded from "./components/Auth/AuthIsLoaded";
import App from "./App";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { firebaseConfig } from "./constants/cloud_constants";
import "./index.css";
import "./firebaseui-styling.global.css";

firebase.initializeApp(firebaseConfig);
// Initialize Cloud Firestore through Firebase
firebase.firestore();

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  // profileFactory: (userData: any, profileData: any, firebase: any) => {
  //   // defines how profiles are stored in database
  //   const { user } = userData;
  //   // return profileData
  //   return {
  //     email: user.email,
  //   };
  // },
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableRedirectHandling: true,
  autoPopulateProfile: true,
  updateProfileOnLogin: true,
};
const rrfProps = {
  firebase,
  config: rrfConfig,
  firebaseConfig,
  dispatch: store.dispatch,
  // createFirestoreInstance, // <- needed if using firestore
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <AuthIsLoaded>
            <App />
          </AuthIsLoaded>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
